import React, { Fragment, useEffect, useState } from "react";
import {
  Breadcrumb,
  Typography,
  Divider,
  Input,
  Drawer,
  Space,
  Button,
  Form,
  Upload,
  message,
  Select,
  InputRef,
} from "antd";
import seekSolutionApi from "../../utils/seekSolutionApi";
import { Link, useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";  
import { ICategory } from "../../context/interfaces";
const { Title } = Typography;

const CoursesEdit = () => {
  const {state } = useLocation();
  const navigate = useNavigate();
  const [imageUrl, setImageUrl] = useState<string>();
  const firebaseStorage = getStorage();
  const categoryInputRef = React.useRef<InputRef>(null);

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [courses, setCourses] = useState({
    Count: 0,
    ScannedCount: 0,
    Items: [],
  });
  const [dropdownlist, setDropdownList]: any = useState([]);

  const [category, setCategory] = React.useState<{ count: number, data: Array<ICategory>, text: string }>({
    count: 0,
    data: [],
    text: "",
  });



  const initialValues = {
    title: state.title,
    desc: state.desc,
    price: state.price,
    categoryId: state.categoryId,
    icon:state.icon
  };

  const [topic, setTopic] = React.useState({
    Count: 0,
    ScannedCount: 0,
    Items: [],
    text: "",
  });
  const [technology, setTechnology] = React.useState({
    Count: 0,
    ScannedCount: 0,
    Items: [],
    text: "",
  });
  


  const addCategory = async (
    e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>
  ) => {
    e.preventDefault();
    try {
      setLoading(true);
      let apiRes = await seekSolutionApi.Category.create({
        name: category.text,
      });
      setCategory({
        ...category,
        data: [...category.data, apiRes] as any,
        text: "",
      });
      setTimeout(() => {
        categoryInputRef.current?.focus();
      }, 0);
    } catch (error) {
      console.error("Error adding category", error);
    } finally {
      setLoading(false);
    }
  };


  console.log("state======>", state);
  

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const getCoursesData = async () => {
    try {
      let apiRes = await seekSolutionApi.Courses.list();
      if (apiRes) {
        console.log("apiRes get courses->", apiRes);
        const newState = {
          Count: apiRes.length,
          ScannedCount: apiRes.length,
          Items: apiRes.map((item: any) => ({
            id: item._id,
            title: item.title,
            createdAt: moment(item.createdAt).format("MMM, DD YYYY"),
          })),
        };
        setCourses(newState);
      }
    } catch (error) {
      console.error("Error fetching courses data", error);
    }
  };

  useEffect(() => {
    getCoursesData();
    getCoursesList();
    initialiseCategory();
    // initialise(URL)
  }, []);

  const initialise = async (values: any) => {
    console.log("init form value ==", values);
    const { title, desc, icon, price, categoryId } = values;
    console.log("title ->", title);
    console.log("desc ->", desc);
    console.log("price ->", price);
    console.log("categoryId ->", categoryId);
    console.log("icon ->", icon);
    
    // console.log("icon ->", URL.createObjectURL(icon));
    // let iconNew = "adsdfasdfz"

    try {
      setLoading(true);
      // console.log("state._id-------------->", state.slug);

      if (!values.icon) {
        return
      }
      const singleFile = values.icon.file.originFileObj
      
      const storageRef = ref(firebaseStorage, 'users/' + new Date().getTime() + singleFile.name);

      const uploaded = await uploadBytes(storageRef, singleFile)
      console.log("uploaded", uploaded);

      setLoading(true);
      let apiRes = await seekSolutionApi.Courses.update(state.slug, {
        ...values,
        // icon: values.icon.file.originFileObj,
        icon:uploaded.ref.fullPath
      });
      console.log("apiRes", apiRes);
      form.resetFields();

      if (apiRes) {
        console.log("apiRes Topic-->>", apiRes);
        navigate("/courses/page/1");
      }
    } catch (error) {
      console.error("Error updating course", error);
    } finally {
      setLoading(false);
    }
  };



  const handleSubmit = async (values: any) => {
    console.log("values=>",values);
    
    const formData = {
      ...values,
      

    };
    setLoading(true);
    localStorage.setItem("courses_draft", JSON.stringify(values));
    await initialise(formData);
    // await initialised(values);
    setLoading(false);
  };


  const initialiseCategory = async () => {
    try {
      let apiRes = await seekSolutionApi.Category.list();
      setCategory({ ...apiRes, text: "" });
    } catch (error) { }
  };
  const initialiseTopic = async (category_id: string) => {
    if (!category_id) {
      return;
    }
    try {
      let apiRes = await seekSolutionApi.Topic.list();
      // let apiRes = await seekSolutionApi.Topic.list(category_id);
      setTopic(apiRes);
    } catch (error) { }
  };

  const getCoursesList = () => {
    console.log("Function running");
    let list: any = [];
    courses.Items.map((item: any) => {
      console.log(item);
      list.push({ id: item.id, label: item.title });
    });
    console.log("list", list);
    setDropdownList(list);
  };

  console.log("Dropdownlist", dropdownlist);

  const beforeUpload = (file: any) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('Image must smaller than 2MB!');
    }
   
    return isJpgOrPng && isLt2M;
   

  };

  
  const handleChange = (info: any) => {
    if (info.file) {
      const obj = info.file.originFileObj
      setImageUrl(URL.createObjectURL(obj))
    }
  };

  const getBase64 = (img: Blob, callback: (url: string) => void) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result as string));
    reader.readAsDataURL(img);
  };
  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  return (
    <Fragment>
      <Space direction="vertical" style={{ display: "flex" }}>
        <Breadcrumb
          items={[
            {
              title: "Management",
            },
            {
              title: <Link to={`/courses/page/1`}>Courses</Link>,
            },
            {
              title: "Edit",
            },
          ]}
        />
        <Title level={2}>Edit Course</Title>
        
        <Divider />
        <Form
          onFinish={handleSubmit}
          form={form}
          initialValues={initialValues}
          layout="vertical"
        >
          <Form.Item
          name={"icon"}
          label="Icon"
          required
          tooltip="This is a required field"
        >
          <Upload
            listType="picture-card"
            className="avatar-uploader"
            accept="image/*"
            action={"#"}
            showUploadList={false}
            beforeUpload={beforeUpload}
            onChange={handleChange}
          >
            {imageUrl ? (
              <img src={imageUrl} alt="avatar" style={{ width: "100%" }} />
            ) : (
              uploadButton
            )}
          </Upload>
        </Form.Item>

          <Form.Item
            name="title"
            label="Title"
            required
            tooltip="title is required"
          >
            <Input placeholder="Enter the course name" />
          </Form.Item>
          <Form.Item
            name="desc"
            label="Desc"
            
            tooltip="desc are required"
          >
            <Input placeholder="Enter the amenities" />
          </Form.Item>
          <Form.Item
            name="price"
            label="Price"
            required
            tooltip="price is required"
          >
            <Input placeholder="Enter the topic" />
          </Form.Item>
          <Form.Item
            name={"categoryId"}
            label="CategoryId"
            required
            tooltip="This is a required field"
          >
            <Select
              style={{ width: "100%" }}
              onChange={(e) => initialiseTopic(e)}
              options={category?.data?.map((res) => {
                return { value: res._id, label: res.name };
              })}
              dropdownRender={(menu) => (
                <>
                  {menu}
                  <Divider style={{ margin: "8px 0" }} />
                  <Space style={{ padding: "0 8px 4px" }}>
                    <Input
                      placeholder="Please enter item"
                      ref={categoryInputRef}
                      value={category.text}
                      disabled={loading}
                      onChange={(e) =>
                        setCategory({
                          ...category,
                          text: e.target.value,
                        })
                      }
                    />
                    <Button
                      type="text"
                      icon={<PlusOutlined />}
                      onClick={addCategory}
                      loading={loading}
                    >
                      Add new category
                    </Button>
                  </Space>
                </>
              )}
            />
          </Form.Item>
          <Button type="primary" htmlType="submit" loading={loading}>
            Update
          </Button>
        </Form>
      </Space>
      <Drawer
        title="Filter"
        placement="right"
        width={500}
        onClose={onClose}
        open={open}
        extra={
          <Space>
            <Button onClick={onClose}>Cancel</Button>
            <Button type="primary" onClick={onClose}>
              Apply
            </Button>
          </Space>
        }
      >
        <p>Some Courses...</p>
      </Drawer>
    </Fragment>
  );
};

export default CoursesEdit;
